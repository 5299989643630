<template>
  <div style="color: rgb(55, 53, 47)">
    <MobileHeader v-if="isMobile"/>
    <BeeHeader v-else/>
    <LayoutArticle :class="isMobile ? 'mobile-view' : 'py-10'">
      <div class="max-w-prose mx-auto" :class="isMobile ? 'text-xs p-3' : 'text-lg'">
        <article id="fad76b41-8a8c-4a46-8072-a525eb95766f" class="page sans">
          <header>
            <h1 class="page-title text-center"><strong>CHÍNH SÁCH BẢO VỆ THÔNG TIN CÁ NHÂN KHÁCH HÀNG</strong></h1>
          </header>
          <div class="page-body">
            <p id="3b4a58a5-6361-47f6-a0de-996e9cb8f497" class="">EcomViet Metric là website cung cấp (bán) phần mềm phân tích thị trường và quản lý bán hàng cho những người có nhu cầu đăng ký sử dụng dịch vụ. Chính sách này mô tả thông tin mà chúng tôi xử lý để hỗ trợ EcomViet Metric nhằm nâng cao chất lượng sản phẩm cũng như đảm bảo dịch vụ được cung cấp một cách tối ưu nhất cho bạn và những người khác. Để cung cấp Sản phẩm của EcomViet Metric, chúng tôi phải xử lý các thông tin bạn cung cấp hoặc chia sẻ. Các loại thông tin mà chúng tôi xử lý phụ thuộc vào cách bạn sử dụng sản phẩm của chúng tôi.</p>
            <h3 id="d666d94c-2db8-4271-86ea-56baac69cd13" class=""><strong>Mục đích và phạm vi thu thập thông tin</strong></h3>
            <p id="100840bc-6445-4dbc-a46b-708ee2f21204" class="">EcomViet Metric thu thập các thông tin sau của người sử dụng dịch vụ:</p>
            <ul id="307ac40d-dde9-4158-8bb8-1d18866cb624" class="bulleted-list">
              <li style="list-style-type:disc"><strong>Thông tin và nội dung bạn cung cấp:</strong> Chúng tôi thu thập nội dung, thông tin liên lạc và các thông tin khác mà bạn cung cấp khi sử dụng sản phẩm của chúng tôi, bao gồm cả khi bạn đăng ký một tài khoản, tạo hoặc chia sẻ nội dung và nhắn tin hay liên lạc với người khác bằng các nền tảng mà chúng tôi cung cấp. Thông tin này cũng có thể bao gồm nội dung bạn nhìn thấy thông qua các tính năng chúng tôi cung cấp.</li>
            </ul>
            <ul id="6ec65f6e-da7b-4ca2-9151-9076c54ecb6a" class="bulleted-list">
              <li style="list-style-type:disc"><strong>Mạng và kết nối:</strong> Chúng tôi thu thập thông tin về những người, trang, tài khoản, hashtag và nhóm mà bạn kết nối cũng như cách bạn tương tác với họ trên các Sản phẩm của chúng tôi. Ngoài ra, chúng tôi còn thu thập thông tin liên hệ nếu bạn thực hiện tải lên thông tin của bạn (chẳng hạn như số địa chỉ, nhật ký cuộc gọi hoặc nhật ký SMS).</li>
            </ul>
            <ul id="0c269d34-faee-4b2c-9206-f2a522ca124c" class="bulleted-list">
              <li style="list-style-type:disc"><strong>Lưu trữ dữ liệu bạn hoặc khách hàng của bạn chia sẻ:</strong> Trong một số trường hợp, nhằm cung cấp đầy đủ dịch vụ của mình, chúng tôi có thể cần lưu trữ dữ liệu của bạn hoặc khách hàng của bạn đã cung cấp qua hệ thống EcomViet Metric. Ví dụ như dữ liệu về số điện thoại khách hàng đã để lại trong tin nhắn trên trang của bạn sẽ được lưu về hệ thống Quản lý khách hàng của bạn trên hệ thống EcomViet Metric.</li>
            </ul>
            <ul id="e054d927-9f2a-4ccd-bb3d-cd39d7734abe" class="bulleted-list">
              <li style="list-style-type:disc"><strong>Thông tin về thiết bị:</strong><em><strong> </strong></em>Như mô tả bên dưới, chúng tôi thu thập thông tin từ và về máy tính, điện thoại được kết nối cũng như các thiết bị kết nối web khác mà bạn dùng để tích hợp Sản phẩm của chúng tôi. Chúng tôi cũng kết hợp thông tin này trên các thiết bị khác nhau mà bạn sử dụng. Ví dụ: chúng tôi sử dụng thông tin thu thập được về cách bạn dùng Sản phẩm của chúng tôi trên điện thoại để cá nhân hóa tốt hơn nội dung hoặc tính năng bạn thấy khi sử dụng Sản phẩm của chúng tôi trên một thiết bị khác, chẳng hạn như máy tính xách tay, máy tính bảng, hoặc để đo lường xem liệu bạn có thực hiện các tính năng chúng tôi cung cấp trên các thiết bị di động hay không. Thông tin chúng tôi thu được từ các thiết bị này bao gồm:</li>
            </ul>
            <ul id="a2155a20-ca61-455b-a9c5-4dc63f87012a" class="bulleted-list">
              <li style="list-style-type:disc"><strong>Các thuộc tính thiết bị:</strong> Những thông tin như hệ điều hành, phiên bản phần cứng và phần mềm, mức pin, cường độ tín hiệu, dung lượng bộ nhớ trống, plugin, loại trình duyệt, tên cũng như loại của tệp và ứng dụng.</li>
            </ul>
            <ul id="a758c4d7-cc04-4c62-a12f-a358efec3742" class="bulleted-list">
              <li style="list-style-type:disc"><strong>Hoạt động trên thiết bị:</strong> Thông tin về các hoạt động và hành vi được thực hiện trên thiết bị, chẳng hạn như liệu một cửa sổ ở nền trước hay nền sau hoặc các hoạt động của chuột (thông tin này có thể giúp phân biệt người thật với các bot).</li>
            </ul>
            <ul id="807525aa-abe9-4d61-a3b3-55df3992e9e8" class="bulleted-list">
              <li style="list-style-type:disc"><strong>Số nhận dạng:</strong> Số nhận dạng duy nhất, ID thiết bị và các số nhận dạng khác, chẳng hạn như từ trò chơi, ứng dụng hoặc tài khoản bạn dùng, cũng như ID thiết bị gia đình.</li>
            </ul>
            <ul id="a6dff711-dd3b-4303-b8bd-b3af871f77e3" class="bulleted-list">
              <li style="list-style-type:disc"><strong>Tín hiệu thiết bị:</strong> Tín hiệu Bluetooth và thông tin về các cột phát sóng, đèn hiệu và điểm truy cập Wi-Fi ở gần.</li>
            </ul>
            <ul id="64093726-e720-4280-b934-80a103171b33" class="bulleted-list">
              <li style="list-style-type:disc"><strong>Dữ liệu từ cài đặt thiết bị:</strong> thông tin bạn cho phép chúng tôi nhận thông qua cài đặt thiết bị mà bạn bật, chẳng hạn như quyền truy cập vào vị trí GPS, máy ảnh hoặc ảnh của bạn.</li>
            </ul>
            <ul id="71699afb-110a-44b8-ba38-9246ed43ef43" class="bulleted-list">
              <li style="list-style-type:disc"><strong>Mạng và kết nối:</strong> thông tin như tên của nhà mạng di động hoặc ISP, ngôn ngữ, múi giờ, số điện thoại di động, địa chỉ IP, tốc độ kết nối.</li>
            </ul>
            <ul id="e18d0fdc-6240-4100-9f4f-ade4bb7350cd" class="bulleted-list">
              <li style="list-style-type:disc"><strong>Dữ liệu cookie:</strong> Dữ liệu từ các cookie được lưu trữ trên thiết bị của bạn, bao gồm cả cài đặt và ID cookie. Hãy tìm hiểu thêm về cách chúng tôi sử dụng cookie trong Chính sách cookie của EcomViet Metric.</li>
            </ul>
            <ul id="0c436a90-7949-4c9e-b62a-e488676b0a90" class="bulleted-list">
              <li style="list-style-type:disc"><em><strong>Thông tin từ đối tác:</strong></em> Nhà quảng cáo, nhà phát triển ứng dụng và nhà phát hành có thể gửi thông tin cho chúng tôi qua Công cụ kinh doanh trên EcomViet Metric mà họ sử dụng.</li>
            </ul>
            <p id="1e12e538-76c4-4770-aa9a-2a3d509d3f8b" class="">
            </p>
            <p id="66531f93-0f29-402b-b7b1-8ab7bd04b62f" class="">Đây là các thông tin mà EcomViet Metric cần thành viên cung cấp bắt buộc khi đăng ký sử dụng dịch vụ và chúng tôi sử dụng thông tin mình có như mô tả bên dưới. Đồng thời, chúng tôi sử dụng những thông tin này để cung cấp và hỗ trợ Sản phẩm của EcomViet Metric và các dịch vụ có liên quan như mô tả trong Điều khoản dịch vụ của EcomViet Metric:</p>
            <ul id="b55cf5fe-7ed8-4945-be44-61a3728b1845" class="bulleted-list">
              <li style="list-style-type:disc"><em><strong>Cung cấp, cá nhân hóa và cải tiến Sản phẩm:</strong></em> Thông tin trên khắp các thiết bị và Sản phẩm của EcomViet Metric: Chúng tôi kết nối thông tin về hoạt động của bạn trên những thiết bị và Sản phẩm khác nhau của EcomViet Metric để mang lại trải nghiệm nhất quán và phù hợp hơn trên tất cả các Sản phẩm của EcomViet Metric mà bạn sử dụng, bất kể nơi bạn sử dụng. Ví dụ: chúng tôi cố gắng mang lại cho bạn trải nghiệm liền mạch hơn, chẳng hạn như bằng cách tự động điền thông tin đăng ký của bạn (như số điện thoại của bạn) từ một Sản phẩm của EcomViet Metric khi bạn đăng ký tài khoản trên một Sản phẩm khác.</li>
            </ul>
            <ul id="58bb4696-c9ce-420c-84c8-b951c51980db" class="bulleted-list">
              <li style="list-style-type:disc"><em><strong>Nghiên cứu và phát triển sản phẩm: </strong></em>Chúng tôi sử dụng thông tin mình có để phát triển, thử nghiệm và cải thiện Sản phẩm, chẳng hạn như bằng cách tiến hành khảo sát và nghiên cứu, cũng như thử nghiệm và khắc phục sự cố các tính năng, sản phẩm mới.</li>
            </ul>
            <ul id="08737493-0201-44ec-9bfc-43abb0cf16c9" class="bulleted-list">
              <li style="list-style-type:disc"><em><strong>Cung cấp dịch vụ đo lường, phân tích và các dịch vụ kinh doanh khác:</strong></em> Chúng tôi sử dụng thông tin mình có để giúp bạn, đồng nghiệp của bạn hoặc các đối tác của chúng tôi có thể đo lường, phân tích và đưa ra các tính hỗ trợ cho bạn trong suốt quá trình sử dụng sản phẩm. Việc này hoàn toàn nằm trong cài đặt của bạn. Bất cứ lúc nào dù không vì lý do gì bạn đều có thể tắt hoặc ngắt kết nối với các tính năng này trong hệ thống.</li>
            </ul>
            <ul id="bb45e4d2-ce6a-4375-8308-cb9c4f4590da" class="bulleted-list">
              <li style="list-style-type:disc"><em><strong>Cải thiện độ an toàn, tính toàn vẹn và bảo mật:</strong></em> Chúng tôi sử dụng thông tin mình có để xác minh tài khoản và hoạt động, chống hành vi có hại, phát hiện và ngăn chặn spam cũng như các trải nghiệm tiêu cực khác, duy trì tính toàn vẹn của Sản phẩm, đồng thời cải thiện độ an toàn và tính bảo mật trên và ngoài Sản phẩm của EcomViet Metric. Ví dụ: chúng tôi sử dụng dữ liệu mình có để điều tra hoạt động đáng ngờ hoặc các hành vi vi phạm chính sách hoặc điều khoản của chúng tôi hoặc phát hiện khi một người nào đó cần trợ giúp.</li>
            </ul>
            <ul id="ae4a3fff-9a9c-4273-b2fa-dafdaa5b4fbb" class="bulleted-list">
              <li style="list-style-type:disc"><em><strong>Liên lạc với bạn:</strong></em> Chúng tôi sử dụng thông tin mình có để gửi cho bạn các thông tin tiếp thị, thông báo cho bạn về Sản phẩm của chúng tôi, cũng như cho bạn biết về các chính sách và điều khoản của chúng tôi. Chúng tôi cũng sử dụng thông tin của bạn để trả lời bạn khi bạn liên hệ với chúng tôi.</li>
            </ul>
            <ul id="caa2c118-e097-467e-a8a7-5daaec21fd7d" class="bulleted-list">
              <li style="list-style-type:disc"><em><strong>Nghiên cứu và đổi mới vì cộng đồng:</strong></em> Chúng tôi sử dụng thông tin mình có (bao gồm thông tin từ các đối tác nghiên cứu cộng tác với chúng tôi) để tiến hành và hỗ trợ cho sự phát triển chung của cộng đồng, xã hội, đặc biệt là cộng đồng những người đang sử dụng sản phẩm của EcomViet Metric.</li>
            </ul>
            <p id="2c87c305-c900-4d3c-9c35-d02f9e284e9b" class="">
            </p>
            <p id="0704488b-3edf-4e92-8c31-c943b3dc86ce" class="">Các thành viên sẽ tự chịu trách nhiệm về bảo mật và lưu giữ mọi hoạt động sử dụng dịch vụ dưới tên đăng ký, mật khẩu và hộp thư điện tử của mình. Ngoài ra, thành viên có trách nhiệm thông báo kịp thời cho EcomViet Metric về những hành vi sử dụng trái phép, lạm dụng, vi phạm bảo mật, lưu giữ tên đăng ký và mật khẩu của bên thứ ba để có biện pháp giải quyết phù hợp.</p>
            <h3 id="3fdcf72b-3022-4813-8196-a89d8fcb56d3" class=""><strong>Phạm vi sử dụng thông tin và những người hoặc tổ chức có thể tiếp cận được với thông tin cá nhân của khách hàng</strong></h3>
            <ul id="1fc34cb9-a12f-4f57-af68-06d95dfe5e30" class="bulleted-list">
              <li style="list-style-type:disc">Khi bạn chia sẻ và liên lạc bằng Sản phẩm của chúng tôi, bạn chọn đối tượng cho nội dung mình chia sẻ. Ví dụ: khi bạn gửi tin nhắn bằng EcomViet Metric, bạn chọn người sẽ nhận được tin nhắn đó. Mạng của bạn cũng có thể thấy những hành động bạn đã thực hiện trên Sản phẩm của chúng tôi...</li>
            </ul>
            <ul id="e6f36c7f-b22c-4ba1-b547-3a19893facc8" class="bulleted-list">
              <li style="list-style-type:disc">Nội dung người khác chia sẻ hoặc chia sẻ lại về bạn;</li>
            </ul>
            <ul id="f8d734fc-23e6-44e1-9340-b158c4bfc8f6" class="bulleted-list">
              <li style="list-style-type:disc">Bạn nên cân nhắc người mà bạn chọn chia sẻ, vì những người có thể xem hoạt động của bạn trên Sản phẩm của chúng tôi có thể chọn chia sẻ hoạt động đó với người khác trên và ngoài Sản phẩm của chúng tôi, bao gồm cả với những người và doanh nghiệp không thuộc đối tượng chia sẻ của bạn. Ví dụ: khi bạn chia sẻ bài viết hoặc gửi tin nhắn cho những tài khoản cụ thể, họ có thể tải xuống, chụp ảnh màn hình hoặc chia sẻ lại nội dung đó với người khác trên hoặc ngoài Sản phẩm của chúng tôi. Ngoài ra, khi bạn trả lời một bình luận của người khác hoặc bày tỏ cảm xúc về nội dung của họ, bình luận hoặc cảm xúc của bạn sẽ hiển thị với bất cứ ai có thể xem nội dung của người đó và họ có thể thay đổi đối tượng sau này. Các ứng dụng, trang web và phần tích hợp của bên thứ ba có trên hoặc sử dụng Sản phẩm của chúng tôi.</li>
            </ul>
            <ul id="d6b4afee-8605-4eda-a9e4-c3597d2679b2" class="bulleted-list">
              <li style="list-style-type:disc">Khi bạn chọn sử dụng các ứng dụng, trang web hoặc dịch vụ khác của bên thứ ba dùng hoặc được tích hợp với Sản phẩm của chúng tôi, thì các ứng dụng, trang web hoặc dịch vụ đó có thể nhận được thông tin về nội dung bạn đăng hoặc chia sẻ. Chúng tôi không có trách nhiệm đối với các dữ liệu bạn đã chọn để chia sẻ qua các đối tác của chúng tôi. Thông tin mà các dịch vụ của bên thứ ba này thu thập sẽ tuân thủ các điều khoản và chính sách của chính họ, chứ không phải của EcomViet Metric.</li>
            </ul>
            <ul id="e2f531fa-8860-4090-97e4-008908db2a6f" class="bulleted-list">
              <li style="list-style-type:disc">Chủ sở hữu mới: Nếu quyền sở hữu hoặc quyền kiểm soát tất cả hoặc một phần Sản phẩm của chúng tôi hoặc thành phần trong Sản phẩm thay đổi, chúng tôi có thể chuyển thông tin của bạn cho chủ sở hữu mới.</li>
            </ul>
            <ul id="1848f14b-1ef8-437b-b707-09abea2ae82a" class="bulleted-list">
              <li style="list-style-type:disc">Chia sẻ với các đối tác bên thứ ba: Chúng tôi hợp tác với các đối tác bên thứ ba là những người giúp chúng tôi cung cấp và cải thiện Sản phẩm của mình hoặc những người sử dụng Công cụ kinh doanh trên EcomViet Metric để phát triển doanh nghiệp của họ. Mục đích của chúng tôi là để vận hành các công ty của mình và cung cấp dịch vụ tốt nhất cho mọi người trên toàn thế giới. Chúng tôi không bán bất cứ thông tin nào của bạn cho bất kỳ ai và sẽ không bao giờ làm như vậy. Ngoài ra, chúng tôi còn áp dụng các hạn chế nghiêm ngặt đối với cách đối tác có thể sử dụng và tiết lộ dữ liệu chúng tôi cung cấp. Dưới đây là các loại bên thứ ba mà chúng tôi chia sẻ thông tin:</li>
            </ul>
            <ul id="f202f580-5146-42cc-8664-6a17958a5b49" class="bulleted-list">
              <li style="list-style-type:disc">Các đối tác sử dụng dịch vụ phân tích của chúng tôi: Chúng tôi cung cấp thông tin chi tiết và số liệu thống kê tổng hợp để giúp mọi người và doanh nghiệp hiểu rõ cách website của bạn đang hoạt động.</li>
            </ul>
            <ul id="6650fa5a-d1d3-42e4-b5ad-8591f38edb91" class="bulleted-list">
              <li style="list-style-type:disc">Đối tác đo lường: Chúng tôi chia sẻ thông tin về bạn với những công ty tổng hợp thông tin đó để cung cấp dữ liệu phân tích và báo cáo đo lường cho các đối tác của chúng tôi. Ví dụ: Google Analytics.</li>
            </ul>
            <ul id="5d1519e0-bcb1-4dba-b58f-bc0faec1daa5" class="bulleted-list">
              <li style="list-style-type:disc">Đối tác cung cấp hàng hóa và dịch vụ trong Sản phẩm của chúng tôi: Khi bạn đăng ký nhận nội dung phải trả phí hoặc mua mặt hàng nào đó từ người bán trong Sản phẩm của chúng tôi, người sáng tạo nội dung hoặc người bán đó có thể nhận được thông tin công khai của bạn và các thông tin khác mà bạn chia sẻ với họ, cũng như thông tin cần thiết để hoàn thành giao dịch, bao gồm cả chi tiết liên hệ và giao hàng.</li>
            </ul>
            <ul id="c9bd1832-b002-41ce-8985-008997948a18" class="bulleted-list">
              <li style="list-style-type:disc">Nhà cung cấp và nhà cung cấp dịch vụ: Chúng tôi cung cấp thông tin và nội dung cho các nhà cung cấp và nhà cung cấp dịch vụ hỗ trợ công việc của chúng tôi, chẳng hạn như bằng cách cung cấp các dịch vụ cơ sở hạ tầng kỹ thuật, phân tích cách mọi người sử dụng Sản phẩm của chúng tôi, cung cấp dịch vụ khách hàng, hỗ trợ thanh toán hoặc tiến hành khảo sát hay giúp các nhà phát triển tạo ra và kinh doanh các giao diện mẫu website của họ.</li>
            </ul>
            <ul id="14c3e496-aacb-4740-b436-ba771f9dd1e7" class="bulleted-list">
              <li style="list-style-type:disc">Yêu cầu pháp lý hoặc yêu cầu của cơ quan hành pháp: Chúng tôi chia sẻ thông tin với cơ quan hành pháp hoặc theo yêu cầu pháp lý trong những trường hợp được yêu cầu của các cơ quan có thẩm quyền tại nước sở tại.</li>
            </ul>
            <h3 id="94e47636-4127-4e49-8c3b-ca3d7e1d9b89" class=""><strong>Những trường hợp không được chia sẻ:</strong></h3>
            <p id="edad18d6-a3d8-4611-8303-1bae7a969733" class="">+ Một số thông tin của bạn, khách hàng của bạn chia sẻ qua hệ thống EcomViet Metric được coi là bảo mật tuyệt đối. Ví dụ như dữ liệu về thông tin khách hàng để lại trên website của bạn. Chúng tôi cam kết thực hiện các điều khoản sau đối với các dữ liệu được coi là tuyệt đối này:</p>
            <p id="9be675f4-8245-4ffc-970f-a63c7c1e5da0" class="">+ Không chia sẻ cho các bên thứ ba dưới bất kỳ hình thức nào nếu chưa được bạn cho phép. Mọi dữ liệu về khách hàng của bạn, cách thức bạn sử dụng, cấu hình, chia sẻ trên sản phẩm của chúng tôi sẽ không được chia sẻ và khai thác bởi bất cứ bên thứ ba nào ngoại trừ các cá nhân, tổ chức đã được bạn chỉ định.</p>
            <p id="e9c82ed8-1a5b-48a5-9dbe-49735d21c5b5" class="">+ Không sử dụng dữ liệu của bạn để phục vụ cho việc kinh doanh, thương mại các sản phẩm của EcomViet Metric hay bất kỳ bên thứ ba nào dù dưới bất kỳ hình thức nào. Mọi dữ liệu chúng tôi thu thập (Nếu có) sẽ chỉ được sử dụng nhằm mục tiêu nâng cao chất lượng sản phẩm hay tăng trải nghiệm của bạn trên hệ thống mà không được sử dụng để phục vụ các mục tiêu thương mại.</p>
            <p id="1fc714c4-bf0a-4c04-8831-62d139a968ed" class="">+ Không sử dụng dữ liệu của bạn để chống lại bạn. Chúng tôi sẽ không sử dụng mọi dữ liệu của bạn trên hệ thống EcomViet Metric để chống lại bạn dù vì bất kỳ lý do nào, trừ khi có yêu cầu của cơ quan pháp luật.</p>
            <p id="ea2500be-0c8f-4aba-9273-33d3d9e72908" class="">+ Quy trình xử lý khi có yêu cầu đặc biệt từ cơ quan pháp luật: Trong trường hợp bị buộc cung cấp thông tin vì các vấn đề an ninh, xã hội theo quy định của pháp luật và được yêu cầu bởi các cơ quan có thẩm quyền, EcomViet Metric sẽ không có trách nhiệm thông báo cho bạn trừ khi các cơ quan có thẩm quyền cho phép chúng tôi làm việc đó. Việc cung cấp thông tin này không miễn trừ bất kỳ nội dung gì mà chúng tôi thu thập, lưu trữ được từ bạn theo chính sách dữ liệu và điều khoản sử dụng dịch vụ của chúng tôi.</p>
            <h3 id="2c719661-fe7e-49ed-9edc-5e30f7231a26" class=""><strong>Thời gian lưu trữ thông tin</strong></h3>
            <p id="d149dcd9-46fc-45a7-b463-a662343ab73d" class="">Chúng tôi cung cấp cho bạn khả năng truy cập, sửa đổi, sao chép và xóa dữ liệu của mình.</p>
            <p id="f9182835-e11c-411a-8c9f-b6af82c58c0f" class="">Chúng tôi lưu trữ dữ liệu cho đến khi không còn cần cung cấp dịch vụ và Sản phẩm của EcomViet Metric nữa hoặc cho đến khi tài khoản của bạn bị xóa, tùy trường hợp nào xảy ra trước. Tùy theo từng trường hợp, chúng tôi sẽ quyết định dựa trên những yếu tố như bản chất của dữ liệu, lý do thu thập và xử lý dữ liệu, cũng như các nhu cầu lưu giữ vì mục đích hoạt động hoặc pháp lý có liên quan.</p>
            <p id="ea119ed6-c75f-49f7-8890-0180a693be56" class="">Khi bạn xóa tài khoản, chúng tôi sẽ xóa nội dung bạn đã đăng. Còn lại trong mọi trường hợp thông tin cá nhân thành viên sẽ được bảo mật trên máy chủ của EcomViet Metric.</p>
            <h3 id="f4e9bb30-fb20-4ba3-b8c2-041dfc701427" class=""><strong>Địa chỉ của đơn vị thu thập và quản lý thông tin cá nhân</strong></h3>
            <p id="20e1c294-92f7-4193-84f6-eb4fa8741a4f" class="">+ Tên đơn vị: Trung tâm Phát triển thương mại điện tử - EcomViet</p>
            <p id="20e1c294-92f7-4193-84f6-eb4fa8741a4f" class="">+ Tên dự án: EcomViet Metric</p>
            <p id="20e1c294-92f7-4193-84f6-eb4fa8741a4f" class="">+ Địa chỉ: 25 P. Ngô Quyền, Tràng Tiền, Hoàn Kiếm, Hà Nội</p>
            <p id="20e1c294-92f7-4193-84f6-eb4fa8741a4f" class="">+ Điện thoại: 024 2220 5507</p>
            <p id="20e1c294-92f7-4193-84f6-eb4fa8741a4f" class="">+ Email: metric@ecomviet.vn</p>
            <p id="20e1c294-92f7-4193-84f6-eb4fa8741a4f" class="">+ Đơn vị phối hợp: Công ty cổ phần Khoa học Dữ liệu</p>
            <ul id="4fc5541a-6b1e-409d-b651-83308f2bf5b3" class="bulleted-list">
              <li style="list-style-type:disc"><strong>Phương tiện và công cụ để người dùng tiếp cận và chỉnh sửa dữ liệu cá nhân của mình.</strong></li>
            </ul>
            <ul id="4d936cc7-83ee-450f-a1d7-11dd3853928f" class="bulleted-list">
              <li style="list-style-type:disc">Thành viên có quyền tự kiểm tra, cập nhật, điều chỉnh hoặc hủy bỏ thông tin cá nhân của mình bằng cách đăng nhập vào tài khoản và chỉnh sửa thông tin cá nhân hoặc yêu cầu ban quản trị website EcomViet Metric thực hiện việc này.</li>
            </ul>
            <ul id="fa264478-98ec-473b-9ec5-3be6a6b6dd8a" class="bulleted-list">
              <li style="list-style-type:disc">Thành viên có quyền gửi khiếu nại về việc lộ thông tin cá nhân cho bên thứ 3 đến Ban quản trị của website EcomViet Metric. Khi tiếp nhận những phản hồi này, EcomViet Metric sẽ xác nhận lại thông tin, phải có trách nhiệm trả lời lý do và hướng dẫn thành viên khôi phục và bảo mật lại thông tin.</li>
            </ul>
            <h3 id="053f15ff-cc03-482e-8750-37c65bab3883" class=""><strong>Cam kết bảo mật thông tin cá nhân khách hàng</strong></h3>
            <ul id="2e1e701d-606d-476a-846f-6ec7f4ce5c35" class="bulleted-list">
              <li style="list-style-type:disc"><strong></strong>Thông tin cá nhân của thành viên trên website EcomViet Metric được EcomViet Metric cam kết bảo mật tuyệt đối theo chính sách bảo vệ thông tin cá nhân của EcomViet Metric. Việc thu thập và sử dụng thông tin của mỗi thành viên chỉ được thực hiện khi có sự đồng ý của khách hàng đó trừ những trường hợp pháp luật có quy định khác.</li>
            </ul>
            <ul id="81339fdb-42bd-4164-b570-27769d7232a4" class="bulleted-list">
              <li style="list-style-type:disc">Không sử dụng, không chuyển giao, cung cấp hay tiết lộ cho bên thứ 3 nào về thông tin cá nhân của thành viên khi không có sự cho phép đồng ý từ thành viên.</li>
            </ul>
            <ul id="d576dc9f-6cac-4e98-b069-d39fd32e3fb9" class="bulleted-list">
              <li style="list-style-type:disc">Trong trường hợp máy chủ lưu trữ thông tin bị hacker tấn công dẫn đến mất mát dữ liệu cá nhân thành viên, website EcomViet Metric sẽ có trách nhiệm thông báo vụ việc cho cơ quan chức năng điều tra xử lý kịp thời và thông báo cho thành viên được biết.</li>
            </ul>
            <ul id="7168021a-8011-49a0-8de8-3bb18835bbcf" class="bulleted-list">
              <li style="list-style-type:disc">Bảo mật tuyệt đối mọi thông tin giao dịch trực tuyến của Thành viên bao gồm thông tin hóa đơn kế toán chứng từ số hóa tại khu vực dữ liệu trung tâm an toàn cấp 1 của EcomViet Metric.</li>
            </ul>
            <ul id="cc0bb022-5af0-4975-962d-6d0ac34c7696" class="bulleted-list">
              <li style="list-style-type:disc">Ban quản lý website EcomViet Metric yêu cầu các cá nhân khi đăng ký/mua hàng là thành viên, phải cung cấp đầy đủ thông tin cá nhân có liên quan như: Họ và tên, địa chỉ liên lạc, email, điện thoại, … và chịu trách nhiệm về tính pháp lý của những thông tin trên. Ban quản lý website EcomViet Metric không chịu trách nhiệm cũng như không giải quyết mọi khiếu nại có liên quan đến quyền lợi của Thành viên đó nếu xét thấy tất cả thông tin cá nhân của thành viên đó cung cấp khi đăng ký ban đầu là không chính xác.</li>
            </ul>
            <h3 id="80d0040b-5303-4557-a235-f7b1840e801f" class=""><strong>Cơ chế tiếp nhận và giải quyết khiếu nại của người tiêu dùng liên quan đến việc thông tin cá nhân bị sử dụng sai mục đích</strong></h3>
            <p id="b8965ebe-1990-49c0-8ced-3ea4dc1a4a8f" class="">Khi phát hiên thông tin cá nhân của mình bị sử dụng sai mục đích hoặc phạm vi, khách hàng vui ̣ lòng gửi email khiếu nại đến email: <strong>metric@ecomviet.vn</strong> hoặc gọi điện thoại tới <strong>033.806.2221</strong> để khiếu nại và cung cấp chứng cứ liên quan tới vụ viêc cho Ban quản ̣ trị. Ban quản trị cam kết sẽ phản hồi ngay lập tức hoặc muộn nhất là trong vòng 48 giờ làm việc kể từ thời điệm nhận được khiếu nại.</p>
            <p id="1fbda13e-1bc6-4b86-9b73-1276de16e84e" class="">Chúng tôi sẽ thông báo cho bạn trước khi thay đổi chính sách này và cung cấp cho bạn tùy chọn xem lại chính sách đã sửa đổi trước khi bạn chọn tiếp tục sử dụng Sản phẩm của chúng tôi.</p>
          </div>
        </article>
      </div>
    </LayoutArticle>
    <BeeFooter/>
  </div>
</template>

<script>
import BeeHeader from "@/components/partial/header/BeeHeader";
import BeeFooter from "@/components/partial/footer/BeeFooter";
import LayoutArticle from "@/layout/LayoutArticle";
import MobileHeader from "@/components/partial/header/MobileHeader";
import {mixinStoreGetterGlobal} from "@/mixin/store/MixinGlobal";

export default {
  name: "UserPrivacyView",
  components: {
    BeeHeader,
    BeeFooter,
    LayoutArticle,
    MobileHeader
  },
  mixins: [
    mixinStoreGetterGlobal
  ]
}
</script>

<style scoped>

/* cspell:disable-file */
/* webkit printing magic: print all background colors */
html {
  -webkit-print-color-adjust: exact;
}
* {
  box-sizing: border-box;
  -webkit-print-color-adjust: exact;
}

html,
body {
  margin: 0;
  padding: 0;
}
@media only screen {
  body {
    margin: 2em auto;
    max-width: 900px;
    color: rgb(55, 53, 47);
  }
}

body {
  line-height: 1.5;
  white-space: pre-wrap;
}

a,
a.visited {
  color: inherit;
  text-decoration: underline;
}

.pdf-relative-link-path {
  font-size: 80%;
  color: #444;
}

h1,
h2,
h3 {
  letter-spacing: -0.01em;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 0;
}

.page-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0.75em;
}

.mobile-view .page-title {
  font-size: 1rem;
  font-weight: 600;
}
h1 {
  font-size: 1.875rem;
  margin-top: 1.875rem;
}

h2 {
  font-size: 1.5rem;
  margin-top: 1.5rem;
}

h3 {
  font-size: 1.25rem;
  margin-top: 1.25rem;
}

.source {
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 1.5em;
  word-break: break-all;
}

.callout {
  border-radius: 3px;
  padding: 1rem;
}

figure {
  margin: 1.25em 0;
  page-break-inside: avoid;
}

figcaption {
  opacity: 0.5;
  font-size: 85%;
  margin-top: 0.5em;
}

mark {
  background-color: transparent;
}

.indented {
  padding-left: 1.5em;
}

hr {
  background: transparent;
  display: block;
  width: 100%;
  height: 1px;
  visibility: visible;
  border: none;
  border-bottom: 1px solid rgba(55, 53, 47, 0.09);
}

img {
  max-width: 100%;
}

@media only print {
  img {
    max-height: 100vh;
    object-fit: contain;
  }
}

@page {
  margin: 1in;
}

.collection-content {
  font-size: 0.875rem;
}

.column-list {
  display: flex;
  justify-content: space-between;
}

.column {
  padding: 0 1em;
}

.column:first-child {
  padding-left: 0;
}

.column:last-child {
  padding-right: 0;
}

.table_of_contents-item {
  display: block;
  font-size: 0.875rem;
  line-height: 1.3;
  padding: 0.125rem;
}

.table_of_contents-indent-1 {
  margin-left: 1.5rem;
}

.table_of_contents-indent-2 {
  margin-left: 3rem;
}

.table_of_contents-indent-3 {
  margin-left: 4.5rem;
}

.table_of_contents-link {
  text-decoration: none;
  opacity: 0.7;
  border-bottom: 1px solid rgba(55, 53, 47, 0.18);
}

table,
th,
td {
  border: 1px solid rgba(55, 53, 47, 0.09);
  border-collapse: collapse;
}

table {
  border-left: none;
  border-right: none;
}

th,
td {
  font-weight: normal;
  padding: 0.25em 0.5em;
  line-height: 1.5;
  min-height: 1.5em;
  text-align: left;
}

th {
  color: rgba(55, 53, 47, 0.6);
}

ol,
ul {
  margin: 0;
  margin-block-start: 0.6em;
  margin-block-end: 0.6em;
}

li > ol:first-child,
li > ul:first-child {
  margin-block-start: 0.6em;
}

ul > li {
  list-style: disc;
}

ul.to-do-list {
  text-indent: -1.7em;
}

ul.to-do-list > li {
  list-style: none;
}

.to-do-children-checked {
  text-decoration: line-through;
  opacity: 0.375;
}

ul.toggle > li {
  list-style: none;
}

ul {
  padding-inline-start: 1.7em;
}

ul > li {
  padding-left: 0.1em;
}

ol {
  padding-inline-start: 1.6em;
}

ol > li {
  padding-left: 0.2em;
}

.mono ol {
  padding-inline-start: 2em;
}

.mono ol > li {
  text-indent: -0.4em;
}

.toggle {
  padding-inline-start: 0em;
  list-style-type: none;
}

/* Indent toggle children */
.toggle > li > details {
  padding-left: 1.7em;
}

.toggle > li > details > summary {
  margin-left: -1.1em;
}

.selected-value {
  display: inline-block;
  padding: 0 0.5em;
  background: rgba(206, 205, 202, 0.5);
  border-radius: 3px;
  margin-right: 0.5em;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
  white-space: nowrap;
}

.collection-title {
  display: inline-block;
  margin-right: 1em;
}

time {
  opacity: 0.5;
}

.icon {
  display: inline-block;
  max-width: 1.2em;
  max-height: 1.2em;
  text-decoration: none;
  vertical-align: text-bottom;
  margin-right: 0.5em;
}

img.icon {
  border-radius: 3px;
}

.user-icon {
  width: 1.5em;
  height: 1.5em;
  border-radius: 100%;
  margin-right: 0.5rem;
}

.user-icon-inner {
  font-size: 0.8em;
}

.text-icon {
  border: 1px solid #000;
  text-align: center;
}

.page-cover-image {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 30vh;
}

.page-header-icon {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.page-header-icon-with-cover {
  margin-top: -0.72em;
  margin-left: 0.07em;
}

.page-header-icon img {
  border-radius: 3px;
}

.link-to-page {
  margin: 1em 0;
  padding: 0;
  border: none;
  font-weight: 500;
}

p > .user {
  opacity: 0.5;
}

td > .user,
td > time {
  white-space: nowrap;
}

input[type="checkbox"] {
  transform: scale(1.5);
  margin-right: 0.6em;
  vertical-align: middle;
}

p {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.image {
  border: none;
  margin: 1.5em 0;
  padding: 0;
  border-radius: 0;
  text-align: center;
}

.code,
code {
  background: rgba(135, 131, 120, 0.15);
  border-radius: 3px;
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-size: 85%;
  tab-size: 2;
}

code {
  color: #eb5757;
}

.code {
  padding: 1.5em 1em;
}

.code-wrap {
  white-space: pre-wrap;
  word-break: break-all;
}

.code > code {
  background: none;
  padding: 0;
  font-size: 100%;
  color: inherit;
}

blockquote {
  font-size: 1.25em;
  margin: 1em 0;
  padding-left: 1em;
  border-left: 3px solid rgb(55, 53, 47);
}

.bookmark {
  text-decoration: none;
  max-height: 8em;
  padding: 0;
  display: flex;
  width: 100%;
  align-items: stretch;
}

.bookmark-title {
  font-size: 0.85em;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 1.75em;
  white-space: nowrap;
}

.bookmark-text {
  display: flex;
  flex-direction: column;
}

.bookmark-info {
  flex: 4 1 180px;
  padding: 12px 14px 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bookmark-image {
  width: 33%;
  flex: 1 1 180px;
  display: block;
  position: relative;
  object-fit: cover;
  border-radius: 1px;
}

.bookmark-description {
  color: rgba(55, 53, 47, 0.6);
  font-size: 0.75em;
  overflow: hidden;
  max-height: 4.5em;
  word-break: break-word;
}

.bookmark-href {
  font-size: 0.75em;
  margin-top: 0.25em;
}

.sans { font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol"; }
.code { font-family: "SFMono-Regular", Menlo, Consolas, "PT Mono", "Liberation Mono", Courier, monospace; }
.serif { font-family: Lyon-Text, Georgia, ui-serif, serif; }
.mono { font-family: iawriter-mono, Nitti, Menlo, Courier, monospace; }
.pdf .sans { font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol", 'Twemoji', 'Noto Color Emoji', 'Noto Sans CJK JP'; }
.pdf:lang(zh-CN) .sans { font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol", 'Twemoji', 'Noto Color Emoji', 'Noto Sans CJK SC'; }
.pdf:lang(zh-TW) .sans { font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol", 'Twemoji', 'Noto Color Emoji', 'Noto Sans CJK TC'; }
.pdf:lang(ko-KR) .sans { font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol", 'Twemoji', 'Noto Color Emoji', 'Noto Sans CJK KR'; }
.pdf .code { font-family: Source Code Pro, "SFMono-Regular", Menlo, Consolas, "PT Mono", "Liberation Mono", Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK JP'; }
.pdf:lang(zh-CN) .code { font-family: Source Code Pro, "SFMono-Regular", Menlo, Consolas, "PT Mono", "Liberation Mono", Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK SC'; }
.pdf:lang(zh-TW) .code { font-family: Source Code Pro, "SFMono-Regular", Menlo, Consolas, "PT Mono", "Liberation Mono", Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK TC'; }
.pdf:lang(ko-KR) .code { font-family: Source Code Pro, "SFMono-Regular", Menlo, Consolas, "PT Mono", "Liberation Mono", Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK KR'; }
.pdf .serif { font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, 'Twemoji', 'Noto Color Emoji', 'Noto Serif CJK JP'; }
.pdf:lang(zh-CN) .serif { font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, 'Twemoji', 'Noto Color Emoji', 'Noto Serif CJK SC'; }
.pdf:lang(zh-TW) .serif { font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, 'Twemoji', 'Noto Color Emoji', 'Noto Serif CJK TC'; }
.pdf:lang(ko-KR) .serif { font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, 'Twemoji', 'Noto Color Emoji', 'Noto Serif CJK KR'; }
.pdf .mono { font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK JP'; }
.pdf:lang(zh-CN) .mono { font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK SC'; }
.pdf:lang(zh-TW) .mono { font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK TC'; }
.pdf:lang(ko-KR) .mono { font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK KR'; }
.highlight-default {
}
.highlight-gray {
  color: rgb(155,154,151);
}
.highlight-brown {
  color: rgb(100,71,58);
}
.highlight-orange {
  color: rgb(217,115,13);
}
.highlight-yellow {
  color: rgb(223,171,1);
}
.highlight-teal {
  color: rgb(15,123,108);
}
.highlight-blue {
  color: rgb(11,110,153);
}
.highlight-purple {
  color: rgb(105,64,165);
}
.highlight-pink {
  color: rgb(173,26,114);
}
.highlight-red {
  color: rgb(224,62,62);
}
.highlight-gray_background {
  background: rgb(235,236,237);
}
.highlight-brown_background {
  background: rgb(233,229,227);
}
.highlight-orange_background {
  background: rgb(250,235,221);
}
.highlight-yellow_background {
  background: rgb(251,243,219);
}
.highlight-teal_background {
  background: rgb(221,237,234);
}
.highlight-blue_background {
  background: rgb(221,235,241);
}
.highlight-purple_background {
  background: rgb(234,228,242);
}
.highlight-pink_background {
  background: rgb(244,223,235);
}
.highlight-red_background {
  background: rgb(251,228,228);
}
.block-color-default {
  color: inherit;
  fill: inherit;
}
.block-color-gray {
  color: rgba(55, 53, 47, 0.6);
  fill: rgba(55, 53, 47, 0.6);
}
.block-color-brown {
  color: rgb(100,71,58);
  fill: rgb(100,71,58);
}
.block-color-orange {
  color: rgb(217,115,13);
  fill: rgb(217,115,13);
}
.block-color-yellow {
  color: rgb(223,171,1);
  fill: rgb(223,171,1);
}
.block-color-teal {
  color: rgb(15,123,108);
  fill: rgb(15,123,108);
}
.block-color-blue {
  color: rgb(11,110,153);
  fill: rgb(11,110,153);
}
.block-color-purple {
  color: rgb(105,64,165);
  fill: rgb(105,64,165);
}
.block-color-pink {
  color: rgb(173,26,114);
  fill: rgb(173,26,114);
}
.block-color-red {
  color: rgb(224,62,62);
  fill: rgb(224,62,62);
}
.block-color-gray_background {
  background: rgb(235,236,237);
}
.block-color-brown_background {
  background: rgb(233,229,227);
}
.block-color-orange_background {
  background: rgb(250,235,221);
}
.block-color-yellow_background {
  background: rgb(251,243,219);
}
.block-color-teal_background {
  background: rgb(221,237,234);
}
.block-color-blue_background {
  background: rgb(221,235,241);
}
.block-color-purple_background {
  background: rgb(234,228,242);
}
.block-color-pink_background {
  background: rgb(244,223,235);
}
.block-color-red_background {
  background: rgb(251,228,228);
}
.select-value-color-default { background-color: rgba(206,205,202,0.5); }
.select-value-color-gray { background-color: rgba(155,154,151, 0.4); }
.select-value-color-brown { background-color: rgba(140,46,0,0.2); }
.select-value-color-orange { background-color: rgba(245,93,0,0.2); }
.select-value-color-yellow { background-color: rgba(233,168,0,0.2); }
.select-value-color-green { background-color: rgba(0,135,107,0.2); }
.select-value-color-blue { background-color: rgba(0,120,223,0.2); }
.select-value-color-purple { background-color: rgba(103,36,222,0.2); }
.select-value-color-pink { background-color: rgba(221,0,129,0.2); }
.select-value-color-red { background-color: rgba(255,0,26,0.2); }

.checkbox {
  display: inline-flex;
  vertical-align: text-bottom;
  width: 16;
  height: 16;
  background-size: 16px;
  margin-left: 2px;
  margin-right: 5px;
}

.checkbox-on {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Crect%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22%2358A9D7%22%2F%3E%0A%3Cpath%20d%3D%22M6.71429%2012.2852L14%204.9995L12.7143%203.71436L6.71429%209.71378L3.28571%206.2831L2%207.57092L6.71429%2012.2852Z%22%20fill%3D%22white%22%2F%3E%0A%3C%2Fsvg%3E");
}

.checkbox-off {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Crect%20x%3D%220.75%22%20y%3D%220.75%22%20width%3D%2214.5%22%20height%3D%2214.5%22%20fill%3D%22white%22%20stroke%3D%22%2336352F%22%20stroke-width%3D%221.5%22%2F%3E%0A%3C%2Fsvg%3E");
}


</style>
